import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import './index.css';
import './HomePage.css';

const PrivacyAndTerms = () => {
  return (
    <div className="main-div">
      <NavBar />

      {/* <section id="home" className="hero-section"> */}
      <section className="privacy-and-terms">
        {/* <div className="planet-background"/> */}
        <div className="hero-content">
        <div className="twinkle1"/>
        <div className="twinkle2"/>
        <div className="twinkle3"/>

        <h2>Terms and Conditions</h2>
        <p><strong>Last Updated:</strong> [8/22/23]</p>
        
        <p>Welcome to the Aphelion Equity website ("Website"). Please read these Terms and Conditions ("Terms") carefully before using the Website, operated by the Aphelion Equity team ("us", "we", or "our").</p>

        <p>By accessing or using the Website, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Website.</p>

        <h3>1. Purpose of the Website</h3>
        <p>This Website is designed to provide general information about Aphelion Equity and its activities. The information and materials provided on this Website are not intended as an offer to sell or solicitation of an offer to buy any financial instrument or investment.</p>

        <h3>2. Intellectual Property</h3>
        <p>All content, design, graphics, compilation, magnetic translation, digital conversion, and other matters related to the Website are protected under applicable copyrights, trademarks, and other proprietary rights. Reproduction, copying, or redistribution for commercial purposes of any materials or design elements on the Website is strictly prohibited without our prior written consent.</p>

        <h3>3. Limitation of Liability</h3>
        <p>The information provided on this Website is provided "as is" without any representations or warranties, express or implied. We make no representations or warranties in relation to the financial or investment information on this Website. Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness, or suitability of the information found on this Website.</p>

        <h3>4. No Investment Advice</h3>
        <p>The content of this Website is provided for informational purposes only and should not be construed as investment advice. Always consult with an appropriate professional before making any investment decisions.</p>

        <h3>5. Links to Other Sites</h3>
        <p>Our Website may contain links to third-party sites that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party sites or services.</p>

        <h3>6. Changes to This Terms and Conditions</h3>
        <p>We may update our Terms from time to time. We will notify you of any changes by posting the new Terms on this page. You are advised to review this Terms periodically for any changes.</p>

        <h3>7. Contact Us</h3>
        <p>If you have any questions about these Terms, please contact Andrew at andrew@aphelionequity.com.</p>
      {/* </section> */}

      </div>
      </section>

      <Footer />

    </div>

  );
}

export default PrivacyAndTerms;
