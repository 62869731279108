import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import './HomePage.css'; 

const HomePage = () => {
  return (
    <div className="main-div">
      <NavBar />
      
      <section id="home" className="hero-section">
        <div className="planet-background"/>
        <div className="hero-content">
          <div className="twinkle1"/>
          <div className="twinkle2"/>
          <div className="twinkle3"/>
          <h1>Consistently Superior Returns.</h1>
          {/* <h2>The sky is not the limit, our returns are out of this world</h2> */}
          <h2>The fund built for longevity. Designed to outperform in every market environment. Exposure to emerging technology, grounded in cash-flows of the current.</h2>
          {/* <h2>We achieve returns exceeding the broader equity markets.</h2> */}
        </div>
      </section>

      <section id="strategies-section" className="strategies-section">
        <h3>Strategies</h3>

        <p>
          The tiered investment system offers opportunity for stability and accumulation of long term holdings. Having many similar holdings, the fund looks primarily to outperform the NASDAQ 100. The architecture of the portolio with the fund's advanced tools and algorithms allow the fund to collect consistent premiums on holdings while maintaining optimal exposure to the upside.
          Our directed brilliance is to find short-term and long-term opportunities, but to always maintain a long-term perspective.
        </p>
      </section>

      <section className="pillars-section">
        <div className="pillar">
          <h3>Short-term opportunities</h3>
          <p>A reserve of capital is maintainted for short-term unique market opportunities. Any number of positions may be taken to hedge risk or exploit market inefficiencies in options pricing which may take place over a few days or weeks.</p>
        </div>
        <div className="horizontal-divider"></div>
        <div className="pillar">
          <h3>Long-term net accumulation of capital</h3>
          <p>This is the bulk of the portfolio. These are long term holdings primarily in the technology and innovation sectors. Options are sold at optimal strike prices to generate net premium.</p>
        </div>
        <div className="horizontal-divider"></div>
        <div className="pillar">
          <h3>High-stability equities&mdash;The Forever Income</h3>
          <p>A certain percentage of holdings are always allocated toward dividend aristocrats and dividend growth companies. While a cornerstone of the portfolio, the allocation may vary depending on outlook of macroeconomic conditions.</p>
        </div>
      </section>

      <section className="performance-section">
        <div className="performance-text">
          <h3>Performance</h3>
          <h4>Holdings contributing to growth outperformed SPY/QQQ equivalents.</h4>
          <p>
            The fund manages
            8,700
            shares
            across
            29
            long-term holdings.
            Quantity of holdings increased
            10%
            in the last ten months.
          </p>

          {/* <a href="/path-to-prospectus.pdf">View Prospectus</a><span className="a-arrow">&rarr;</span> */}
        </div>
        <div className="charts">
          <div className="chart historical-performance">Historical Performance Chart</div>
          <div className="chart hypothetical-returns">Hypothetical Returns Chart</div>
        </div>
      </section>

      <section className="quote-section">
        "Aphelion Equity is a powerful investing resource that is designed to outperform in multiple market conditions. It’s built to last through periods of shortcomings in the broader markets and gain exposure to revolutions in technology."
        <p className="quote-name">-Andrew Urbanowicz, Founder</p>
      </section>


      <section id="values-header-section" className="values-header-section">
        <h3>Top Holdings (Q3)</h3>
      </section>
      <section className="holdings-section">
        <div className="holdings">
          <div className="value">
            <h2>1. ~ TBD</h2>
            {/* <p></p> */}
          </div>
          <div className="value">
            <h2>3. ~ TBD</h2>
            {/* <p>With a tiered investment system, Aphelion maximizes profitability during times of uncertainty while still capturing the majority of upside in the greatest portion of holdings.</p> */}
          </div>
          <div className="value">
            <h2>5. ~ TBD</h2>
            {/* <p>We are balanced between limiting the upside potential of equity holdings to generate short-term returns (thus contributing to the quantity of holdings over time) and maximizing the potential of appreciation.</p> */}
          </div>
        </div>

        <div className="holdings">
          <div className="value">
            <h2>2. ~ TBD</h2>
            {/* <p></p> */}
          </div>
          <div className="value">
            <h2>4. ~ TBD</h2>
            {/* <p>With a tiered investment system, Aphelion maximizes profitability during times of uncertainty while still capturing the majority of upside in the greatest portion of holdings.</p> */}
          </div>
          <div className="value">
            <h2>6. ~ TBD</h2>
            {/* <p>We are balanced between limiting the upside potential of equity holdings to generate short-term returns (thus contributing to the quantity of holdings over time) and maximizing the potential of appreciation.</p> */}
          </div>
        </div>
      </section>

      <section id="values-header-section" className="values-header-section">
        <h3>Values</h3>
      </section>
      <section className="values-section">
        <div className="value">
          <h2>1.</h2>
          <h3>Data-Informed</h3>
          <p>Aphelion Equity is managed using a suite of exclusive, proprietary tools and algorithms to manage risk, achieve investment targets, and reduce tax liabilities.</p>
        </div>
        <div className="value">
          <h2>2.</h2>
          <h3>Resilient</h3>
          <p>With a tiered investment system, Aphelion maximizes profitability during times of uncertainty while still capturing the majority of upside in the greatest portion of holdings.</p>
        </div>
        <div className="value">
          <h2>3.</h2>
          <h3>Balanced</h3>
          <p>We are balanced between limiting the upside potential of equity holdings to generate short-term returns (thus contributing to the quantity of holdings over time) and maximizing the potential of appreciation.</p>
        </div>
      </section>

      <section id="founder-section" className="founder-section">
        <h3>About the Founder</h3>
        <div className="founder-container">
          <img src="/images/andrew_car.png" alt="Founder's Name"/>
          <p>
            Andrew Urbanowicz has over a decade of experience in software engineering. 
            He first became acquainted with investing in the stock market during his physics degree at UCLA. 
            Since then, Andrew has had over 7 years in the financial engineering space and has used his expertise in mathematics and coding to develop this modern investment management system.
          </p>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default HomePage;
