// components/NavBar.js
import './NavBar.css'; // This will be our CSS file for styling
import React, { useState, useEffect, useRef } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';


const NavBar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const hamburgerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      if (position > 100) {  // You can adjust this value as needed
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (mobileMenuOpen &&  menuRef.current && hamburgerRef.current && !menuRef.current.contains(event.target) && !hamburgerRef.current.contains(event.target)) {
      setMobileMenuOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [mobileMenuOpen]);

  const handleScroll = (event, sectionId) => {
    if (window.location.pathname === '/') {
      event.preventDefault();
      const targetSection = document.getElementById(sectionId);
      if (targetSection) {
        const topPosition = targetSection.getBoundingClientRect().top + window.pageYOffset - 100; // Adjust the '- 100' value to your desired offset
        window.scrollTo({ top: topPosition, behavior: "smooth" });
        setMobileMenuOpen(false);
      }
    } else {
      window.location.href = '/';
    }
  };

  
  return (
    <nav className={`navbar ${isScrolled ? 'solid-bg' : ''}`}>
      <div className="left-side">
        <a href="#home" onClick={(e) => handleScroll(e, 'home')}>
          <img src='/images/LogoLarge.png' alt="Company Logo" className="company-logo"/>
        </a>
        <span className="company-name">Aphelion Equity</span>
      </div>
      
      <div className="right-side">
        <IconButton className="hamburger" onClick={() => setMobileMenuOpen(!mobileMenuOpen)} ref={hamburgerRef}>
          <MenuIcon style={{ color: "#FFF" }} />
        </IconButton>
        
        <div className={`mobile-menu ${mobileMenuOpen ? 'active' : ''}`} ref={menuRef}>
          <a href="#strategies" className="nav-item mobile-item" onClick={(e) => handleScroll(e, "strategies-section")}>Strategies</a>
          <a href="#values" className="nav-item mobile-item" onClick={(e) => handleScroll(e, "values-header-section")}>Values</a>
          <a href="#about" className="nav-item mobile-item" onClick={(e) => handleScroll(e, "founder-section")}>About</a>
        </div>

        {/* The desktop version of the nav items can remain the same as before. */}
        <a href="#strategies" className="nav-item desktop-item" onClick={(e) => handleScroll(e, "strategies-section")}>Strategies</a>
        <a href="#values" className="nav-item desktop-item" onClick={(e) => handleScroll(e, "values-header-section")}>Values</a>
        <a href="#about" className="nav-item desktop-item" onClick={(e) => handleScroll(e, "founder-section")}>About</a>
      </div>
    </nav>
  );
}

export default NavBar;
