import './App.css';
// import Home from './Home';
import HomePage from './Home';
import PrivacyAndTerms from './PrivacyAndTerms';
import { BrowserRouter, Route, Routes } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/privacy-and-terms" element={<PrivacyAndTerms />} />
          </Routes>
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
