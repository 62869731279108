// components/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {

  const handleScroll = (event, sectionId) => {
    if (window.location.pathname === '/') {
      event.preventDefault();
      const targetSection = document.getElementById(sectionId);
      if (targetSection) {
        const topPosition = targetSection.getBoundingClientRect().top + window.pageYOffset - 100; // Adjust the '- 100' value to your desired offset
        window.scrollTo({ top: topPosition, behavior: "smooth" });
      }
    } else {
      window.location.href = '/';
    }
  };

  return (
    <footer className="footer">
      <div className="footer-left-side">
        <div className="logo-section">
        <a href="#home" onClick={(e) => handleScroll(e, 'home')}>
        <img src={'/images/LogoLarge.png'} alt="Company Logo" className="company-logo"/>
        </a>
          <span className="company-name">Aphelion Equity</span>
        </div>
        <div className="footer-text">
          <p className="privacy-text">
            2023 Aphelion Equity. All Rights Reserved.<br/>
            <a href="/privacy-and-terms" className="privacy-link">Privacy and Terms</a></p>
          
        </div>
      </div>
      <div className="footer-right-side">
        <p className="contact-text">Contact</p>
        <a href="mailto:andrew@aphelionequity.com" className="email-text">andrew@aphelionequity.com</a>
        <address className="email-text">
          9531 Santa Monica Blvd, Suite 204<br/>
          Beverly Hills, CA 90210
        </address>
      </div>
    </footer>
  );
}

export default Footer;
